import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  IconButton,
  Button,
  Modal,
  Select,
  MenuItem,
  FormControl,
  Switch,
  CircularProgress,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import { getApi } from "services/axiosInstance";
import { postApi } from "services/axiosInstance";
import { API_PATH } from "services/apipath";

function FillInBlanksSelect({ questionData }) {
  console.log('This component is trigerred');

  const navigate = useNavigate();
  const location = useLocation();
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [loading, setLoading] = useState(true);
  const [answers, setAnswers] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isCorrect, setIsCorrect] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [timer, setTimer] = useState(0);
  const [isTimerRunning, setIsTimerRunning] = useState(true);
  const timerRef = useRef(null);
  // Add new state for tracking blanks count
  const [blanksCount, setBlanksCount] = useState(0);

  const getQuestionIdFromUrl = () => {
    const pathSegments = location.pathname.split("/");
    return pathSegments[pathSegments.length - 1];
  };

  const idFromUrl = getQuestionIdFromUrl();
  const [questionId, setQuestionId] = useState(idFromUrl);

  useEffect(() => {
    console.log(currentIndex, currentQuestion)
    if (totalQuestions > 0 && currentQuestion !== null) {
      setStats({correctAnswers:0,
        incorrectAnswers:0,
        totalAnswers:0
      })
      setLoading(false);
    }
  }, [totalQuestions, currentIndex, currentQuestion]);

  const findIndex = () => {
    const questionIndex = questionData.questions?.findIndex(
      (question) => question.id === questionId
    );
    if (questionIndex !== -1) {
      setCurrentIndex(questionIndex);
    }
  };

  useEffect(() => {
    if (questionData && questionData.totalQuestions) {
      setQuestions(questionData.questions);
      setTotalQuestions(questionData.totalQuestions);
      findIndex();
    }
  }, [questionData]);

  const getQuestionBtID = async (id) => {
    try {
      console.log(id);

      const res = await getApi(`${API_PATH.STUDENTS.GET_QUESTION_BY_ID}/${id}`);

      if (res.status === 200) {
        const filteredQuestion = res.data.data;
        console.log({ filteredQuestion });

        setCurrentQuestion(filteredQuestion);
        if (filteredQuestion) {
          // Count blanks in the question text
          const count = (filteredQuestion.question_text.match(/___/g) || []).length;
          setBlanksCount(count);

          const initialAnswers = {};
          for (let i = 1; i <= count; i++) {
            initialAnswers[`blank${i}`] = "";
          }
          setAnswers(initialAnswers);
        }
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (questionData && questionId) {
      getQuestionBtID(questionId);
    }
  }, [questionId]);

  useEffect(() => {
    if (!currentQuestion) return;
    timerRef.current = setInterval(() => {
      if (isTimerRunning) {
        setTimer(prevTime => prevTime + 1);
      }
    }, 1000);

    // Clear interval when component unmounts
    return () => {
      clearInterval(timerRef.current);
    };
  }, [isTimerRunning, currentQuestion]);

  const resetTimer = () => {
    setTimer(0);
    setIsTimerRunning(true);
  };

  const handleNextQuestion = () => {
    setLoading(true);
    setShowAnswer(false);
    setIsSubmitted(false);
    setIsCorrect({});
    resetTimer();
    if (currentIndex < questions.length - 1) {
      const nextQuestion = questions[currentIndex + 1];
      const nextId = nextQuestion.id;
      setCurrentIndex(currentIndex + 1);
      setQuestionId(nextId);
      setBlanksCount(0);
      setAnswers({});
    }
  };

  const handlePreviousQuestion = () => {
    setLoading(true);
    setShowAnswer(false);
    setIsSubmitted(false);
    setIsCorrect({});
    resetTimer();
    if (currentIndex > 0) {
      const nextQuestion = questions[currentIndex - 1];
      const nextId = nextQuestion.id;
      setCurrentIndex(currentIndex - 1);
      setQuestionId(nextId);
      setBlanksCount(0);
      setAnswers({}); // Clear all previous answers
    }
  };

  const handleAnswerChange = (blank) => (event) => {
    setAnswers({
      ...answers,
      [blank]: event.target.value,
    });
    setIsSubmitted(false);
  };

  const [stats, setStats] = useState({
    correctAnswers: 0,
    incorrectAnswers: 0,
    totalAnswers: 0
  });

  const handleSubmit = async () => {
    if (!currentQuestion) return;

    // Stop the timer when submitting
    setIsTimerRunning(false);

    const correctAnswers = currentQuestion.rwF_ans;
    const userAnswers = Object.values(answers);

    const correctnessMap = {};
    let correctCount = 0;
    let incorrectCount = 0;

    userAnswers.forEach((answer, index) => {
      const isCorrectAnswer = answer === correctAnswers[index];
      correctnessMap[`blank${index + 1}`] = isCorrectAnswer;

      if (isCorrectAnswer) {
        correctCount++;
      } else {
        incorrectCount++;
      }
    });

    // Update total answers to be the blanks count
    const totalAnswers = blanksCount;

    setIsCorrect(correctnessMap);
    setIsSubmitted(true);
    setStats({
      correctAnswers: correctCount,
      incorrectAnswers: incorrectCount,
      totalAnswers: totalAnswers,
    });

    const payload = {
      questionId: currentQuestion._id,
      answers: JSON.stringify(userAnswers),
      correctAnswers: JSON.stringify(correctAnswers),
      timeSpent: timer,
    };

    try {
      const answer = await postApi(API_PATH.STUDENTS.SUBMIT_ANS, payload);
      if (answer.status === 200) {
        // You can add any success handling here
      }
    } catch (error) { }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const handleSearchItemClick = (questionId) => {
    const questionIndex = questions.findIndex((q) => q._id === questionId);
    if (questionIndex !== -1) {
      setCurrentIndex(questionIndex);
      setCurrentQuestion(questions[questionIndex]);
      setShowAnswer(false);

      // Reset answers for the new question
      const initialAnswers = {};
      const count = (questions[questionIndex].question_text.match(/___/g) || []).length;
      setBlanksCount(count);

      for (let i = 0; i < count; i++) {
        initialAnswers[`blank${i + 1}`] = "";
      }
      setAnswers(initialAnswers);
      setIsSubmitted(false);
      setIsCorrect({});
      resetTimer();

      navigate(`/questions/${questionId}`, { replace: true });
      setOpenSearchModal(false);
    }
  };

  const renderSelect = (blankIndex) => {
    if (!currentQuestion || !currentQuestion.rwF) return null;
    const options = currentQuestion.rwF[blankIndex] || [];
    const blankKey = `blank${blankIndex + 1}`;
    const isAnswerSubmitted = isSubmitted;
    const isAnswerCorrect = isCorrect[blankKey];

    return (
      <FormControl
        size="small"
        sx={{ mx: 1, display: "inline-block", verticalAlign: "middle" }}
      >
        <Select
          value={
            showAnswer
              ? currentQuestion.rwF_ans[blankIndex]
              : answers[blankKey] || ""
          }
          onChange={handleAnswerChange(blankKey)}
          disabled={showAnswer}
          displayEmpty
          IconComponent={KeyboardArrowDownIcon}
          sx={{
            height: "25px !important",
            borderRadius: "4px !important",
            width: "auto !important",
            backgroundColor: isAnswerSubmitted
              ? isAnswerCorrect
                ? "rgba(76, 175, 80, 0.1)"
                : "rgba(244, 67, 54, 0.1)"
              : "transparent",
            "& .MuiSelect-select": {
              color: isAnswerSubmitted
                ? isAnswerCorrect
                  ? "#4CAF50"
                  : "#f44336"
                : "#000",
              padding: "0px 8px !important",
              border: "1px solid rgba(255, 255, 255, 0.3)",
              minWidth: "80px !important",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none !important",
            },
          }}
        >
          <MenuItem value="">
            <em style={{ color: "#000" }}>Select</em>
          </MenuItem>
          {options.map((option, index) => (
            <MenuItem key={index} value={option} style={{ color: "#000" }}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  if (loading) {
    return (
      <Box sx={{ bgcolor: "#1a1a2e", minHeight: "100vh", p: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress sx={{ color: "#00BFA6" }} />
        </Box>
      </Box>
    );
  }

  // Improved Answer Box that shows each blank numbered with its correct answer
  const AnswerBox = ({ currentQuestion }) => {
    if (!currentQuestion?.rwF_ans) return null;

    return (
      <Box
        sx={{
          mt: 3,
          p: 3,
          bgcolor: "rgba(255,255,255,0.05)",
          borderRadius: 1,
          border: "1px solid rgba(255,255,255,0.1)",
        }}
      >
        <Typography sx={{ color: "#fff", fontSize: "16px", mb: 2 }}>
          Correct Answers:
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {currentQuestion.rwF_ans.map((answer, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Typography
                sx={{
                  color: "#00A693",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                Blank {index + 1}:
              </Typography>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "14px",
                  bgcolor: "rgba(0, 166, 147, 0.1)",
                  px: 2,
                  py: 0.5,
                  borderRadius: "4px",
                }}
              >
                {answer}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={{ bgcolor: "#1A1B2E", minHeight: "100vh", color: "#fff" }}>
      {/* Header */}
      <Box
        sx={{
          bgcolor: "#1A1B2E",
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid rgba(255,255,255,0.1)",
          position: "sticky",
          top: 0,
          zIndex: 1000,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton sx={{ color: "#fff" }} onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <Typography sx={{ ml: 2, fontSize: "20px" }}>
            R/W Fill in Blanks
          </Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography>{`${currentIndex + 1}/${totalQuestions}`}</Typography>
          <Box sx={{ display: "flex", gap: 0.5 }}>
            <IconButton
              sx={{ color: "#fff" }}
              onClick={() => setOpenSearchModal(true)}
            >
              <i className="fas fa-search" />
            </IconButton>
            <IconButton
              sx={{ color: "#fff" }}
              onClick={handlePreviousQuestion}
              disabled={currentIndex === 0}
            >
              <i className="fas fa-chevron-left" />
            </IconButton>
            <IconButton
              sx={{ color: "#fff" }}
              onClick={handleNextQuestion}
              disabled={currentIndex === questions.length - 1}
            >
              <i className="fas fa-chevron-right" />
            </IconButton>
          </Box>
        </Box>
      </Box>

      {/* Main Content */}
      <Box sx={{ px: 3, pt: 2, }}>
        <Typography
          sx={{
            color: "rgba(255,255,255,0.7)",
            fontStyle: "italic",
            fontSize: "16px",
            mb: 1,
          }}
        >
          Below is a text with blanks. Click on each blank, a list of choices
          will appear. Select the appropriate answer choice for each blank.
        </Typography>

        {currentQuestion && (
          <>
            <Box sx={{ fontSize: "15px !important", color: "rgba(255,255,255,0.9)" }}>
              <Typography component="div" sx={{ display: "inline" }}>
                {currentQuestion.question_text
                  .split("___")
                  .map((part, index, array) => (
                    <React.Fragment key={index}>
                      {part}
                      {index < array.length - 1 && renderSelect(index)}
                    </React.Fragment>
                  ))}
              </Typography>
            </Box>

            {showAnswer && <AnswerBox currentQuestion={currentQuestion} />}

            {/* Feedback Message */}
            {isSubmitted && !showAnswer && (
              <Box
                sx={{
                  mt: 3,
                  p: 2,
                  bgcolor: Object.values(isCorrect).every((v) => v)
                    ? "rgba(76, 175, 80, 0.1)" // Green background for all correct
                    : "rgba(244, 67, 54, 0.1)", // Red background for some incorrect
                  borderRadius: 1,
                  border: Object.values(isCorrect).every((v) => v)
                    ? "1px solid rgba(76, 175, 80, 0.3)"
                    : "1px solid rgba(244, 67, 54, 0.3)",
                }}
              >
                <Typography
                  sx={{
                    color: Object.values(isCorrect).every((v) => v)
                      ? "#4CAF50" // Green text for all correct
                      : "#f44336", // Red text for some incorrect
                  }}
                >
                  {Object.values(isCorrect).every((v) => v)
                    ? "All answers are correct!"
                    : "Some answers are incorrect. Please check the correct answers shown below each selection."}
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>

      {/* Stats Box - Updated to show total blanks */}
      <Box
        sx={{
          p: 2,
          m: 2,
          bgcolor: "rgba(255,255,255,0.05)",
          borderRadius: 1,
          border: "1px solid rgba(255,255,255,0.1)",
        }}
      >
        <Box sx={{ display: "flex", gap: 3, alignItems: "center" }}>
          <Button
            sx={{
              bgcolor: "#00BFA6",
              color: "#fff",
              borderRadius: "8px",
              fontSize: "14px",
              px: 3,
              py: 1,
              "&:hover": { bgcolor: "#00BFA6" },
            }}
          >
            {stats.correctAnswers} / {blanksCount}
          </Button>

          <Typography sx={{ color: "#fff", fontSize: "14px" }}>
            Total blanks: {blanksCount}
          </Typography>
        </Box>
      </Box>

      {/* Fixed Footer */}
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          bgcolor: "#1A1B2E",
          borderTop: "1px solid rgba(255,255,255,0.1)",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
          zIndex: 1000,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 3, ml: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              Show Answer
            </Typography>
            <Switch
              checked={showAnswer}
              onChange={(e) => setShowAnswer(e.target.checked)}
              sx={{
                "& .MuiSwitch-switchBase.Mui-checked": {
                  color: "#00A693",
                },
                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                  backgroundColor: "#00A693",
                },
              }}
            />
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              Attempted Time: {formatTime(timer)}
            </Typography>
          </Box>
        </Box>

        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={showAnswer}
          sx={{
            bgcolor: "#00A693",
            "&:hover": {
              bgcolor: "#009688",
            },
            textTransform: "none",
            px: 4,
            py: 1,
            fontSize: "16px",
            borderRadius: "4px",
            minWidth: "120px",
            color: "#000",
            mr: 2,
          }}
        >
          Submit
        </Button>
      </Box>

      {/* Search Modal */}
      <Modal
        open={openSearchModal}
        onClose={() => setOpenSearchModal(false)}
        aria-labelledby="search-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "#1A1B2E",
            borderRadius: "8px",
            p: 3,
            outline: "none",
            maxHeight: "80vh",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
              borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
              pb: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Select
                value="All"
                size="small"
                sx={{
                  color: "#000",
                  height: "32px",
                  minWidth: "100px",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255,255,255,0.3)",
                    borderRadius: "4px",
                  },
                }}
              >
                <MenuItem value="All">All</MenuItem>
              </Select>
              <Typography sx={{ color: "#000", fontSize: "14px" }}>
                {questions.length} Qs
              </Typography>
            </Box>
            <IconButton
              onClick={() => setOpenSearchModal(false)}
              sx={{ color: "#000" }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {questions.map((question, index) => (
              <Box
                key={question._id || question.id}
                onClick={() => handleSearchItemClick(question._id || question.id)}
                sx={{
                  bgcolor: "#262842",
                  borderRadius: "8px",
                  p: 2,
                  cursor: "pointer",
                  "&:hover": {
                    bgcolor: "#2F315A",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ color: "#000", fontSize: "14px" }}>
                    {index + 1}. Question {index + 1}
                  </Typography>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    {question.isNew && (
                      <Box
                        sx={{
                          bgcolor: "#FFC107",
                          px: 1,
                          borderRadius: "4px",
                          fontSize: "12px",
                          fontWeight: 500,
                          color: "#000",
                        }}
                      >
                        New
                      </Box>
                    )}
                    {question.isVIP && (
                      <Box
                        sx={{
                          bgcolor: "#FFC107",
                          px: 1,
                          borderRadius: "4px",
                          fontSize: "12px",
                          fontWeight: 500,
                          color: "#000",
                        }}
                      >
                        VIP
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box sx={{ mt: 1 }}>
                  <Typography
                    sx={{
                      color: "rgba(255,255,255,0.6)",
                      fontSize: "12px",
                    }}
                  >
                    Attempted: {question.attempted || 0}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default FillInBlanksSelect;